import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import FooterTwo from '../common/footer/FooterTwo';
import SplashFooter from '../common/footer/SplashFooter';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import BannerOne from '../component/banner/BannerOne';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import PricingOne from '../component/pricing/PricingOne';
import ProjectOne from '../component/project/ProjectOne';
import ProjectTwo from '../component/project/ProjectTwo';
import ProjectThree from '../component/project/ProjectThree';
import ProjectFour from '../component/project/ProjectFour';
import ProjectFive from '../component/project/ProjectFive';
import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import SplashCta from '../component/cta/SplashCta';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
    }, [location]);

    return null; // Ne rend rien, il est juste là pour gérer le scroll
};

const description = "Pâtisserie artisanale sur commande dans la région de Paimpol. Découvrez nos desserts traditionnels et innovants, réalisés avec des ingrédients de qualité et un savoir-faire d'exception. Que ce soit pour un événement particulier ou professionnel, Léonie Dessert s'adapte à vos besoins."

const Home = () => {
    return (
        <>
        <SEO title="Léonie Dessert - Pâtisserie sur commande" description={description}/>
        <main className="main-wrapper">
            <ScrollToHashElement/>
            <HeaderOne />
            <BannerOne />
            <ProjectThree />
            <div className="section section-padding">
                <div className="container">
                <SectionTitle 
                    subtitle="Découvrez notre savoir-faire artisanal au service de votre plaisir sucré"
                    title="Les services que <br>Léonie Dessert vous propose"
                    description=""
                    textAlignment="heading-left mb--20 mb_md--70"
                    textColor=""
                />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-6" serviceStyle="service-style-2" itemShow="4" marginTop="no"/>
                    </div>
                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="Line" /></li>
                </ul>

            </div>
            {/* <TestimonialOne /> */}
            <SplashCta />
            <SplashFooter />
        </main>
        </>
    )
}

export default Home;

