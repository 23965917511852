import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';


// Home Pages Import
import Home from './pages/Home';
import Category from './pages/Category';
import Product from './pages/Product';
import Contact from './pages/Contact';
import ComingSoon from './pages/ComingSoon';
import LegalNotices from './pages/LegalNotices'
import TermsOfUse from './pages/TermsOfUse';

// Css Import
import './assets/scss/app.scss';


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<Home />}/>
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/categorie/:slug"} element={<Category />}/>
				<Route path={process.env.PUBLIC_URL + "/produit/:slug"} element={<Product />}/>
				<Route path={process.env.PUBLIC_URL + "/evenements"} element={<ComingSoon />}/>
				<Route path={process.env.PUBLIC_URL + "/mentions-legales"} element={<LegalNotices />}/>
			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
