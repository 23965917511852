import React from 'react'
import PropTypes from "prop-types";


const SEO = ({ title, description, imageUrl }) => {
    const currentUrl = window.location.href
    const ogImage = imageUrl !== undefined ? imageUrl : `${process.env.PUBLIC_URL}/images/icon/home-og-image.png`

    return (
        <>
            <meta charSet="utf-8" />
            <link rel="canonical" href={currentUrl} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

            <title>{title}</title>
            <meta name="description" content={description} />

            <meta name="og:title" content={title} />
            <meta name="og:description" content={description}/>
            <meta name="og:url" content={currentUrl}/>
            <meta name="og:image" content={ogImage}/>
        </>
    )
}

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string
};

export default SEO;