import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import SEO from '../common/SEO';
import ProjectOne from '../component/project/ProjectOne';
import { useParams } from 'react-router-dom';
import useFetch from '../hook/useFetch';
import SplashFooter from '../common/footer/SplashFooter';


const Category = () => {
    const { slug } = useParams();
    const { data: categorie } = useFetch(process.env.REACT_APP_API_URL + `/cake-categories?slug=${slug}`);

    return (
        <>
        <SEO title={`Léonie Dessert - ${categorie.name}`} description={categorie.description} imageUrl={categorie.image} />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerTwo 
                title={categorie.name}
                paragraph={categorie.description}
                styleClass=""
                mainThumb={categorie.image}
            />
            <ProjectOne colSize="col-xl-4 col-md-6" columnGap="row-15" categoryId={categorie._id}/>
            <SplashFooter parentClass="" />
        </main>
        </>
    )
}

export default Category;