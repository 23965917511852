import React from 'react';
import {Link, useParams} from 'react-router-dom';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import Accordion from 'react-bootstrap/Accordion';
import { FaListUl, FaTag, FaHandHoldingMedical } from 'react-icons/fa';
import SEO from '../common/SEO';
import useFetch from '../hook/useFetch';
import SplashFooter from '../common/footer/SplashFooter';
import CakePricing from '../component/pricing/CakePricing'

const Product = () => {
    const { slug } = useParams();
    const { data: cake } = useFetch(process.env.REACT_APP_API_URL + `/cakes?slug=${slug}`);

    return (
        <>
        <SEO title={`Léonie Dessert - ${cake.name} `} description={cake.description} imageUrl={cake.image}/>
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerTwo 
                title={cake.name}
                paragraph ={cake.description}
                mainThumb={cake.image}
            />
            <section className="section-padding single-portfolio-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-heading heading-left mb-0">
                                <h3 className="title">Description</h3>
                                <div style={{textAlign: 'justify'}}>
                                    <div dangerouslySetInnerHTML={{__html: cake.descriptionLong }}></div>

                                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 25, marginBottom: 25}}>
                                        <Link to="/contact" className="axil-btn btn-fill-primary">Commander</Link>
                                    </div>

                                    <div style={{textAlign: 'justify'}}>
                                        Dans un souci d'organisation et pour vous servir au mieux, merci de prévoir votre commande de préférence une semaine à l'avance et un mois pour une commande importante.
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 offset-xl-1">
                            <div className="why-choose-us">
                                <h3 className="title">Informations</h3>
                                <div dangerouslySetInnerHTML={{__html: cake.informations }}></div>
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><FaTag /> Tarif</Accordion.Header>
                                        <Accordion.Body>
                                            <CakePricing cake={cake}/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><FaListUl /> Compositon</Accordion.Header>
                                        <Accordion.Body>
                                            {cake.ingredients && cake.ingredients.map(ingredient => ingredient.name).join(', ')}
                                            {cake.ingredients && cake.ingredients.some(ingredient => ingredient.isAlcool) && (
                                                <div style={{ color: 'red', marginTop: '10px', fontStyle: 'italic' }}>
                                                    Ce produit contient de l'alcool
                                                </div>
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><FaHandHoldingMedical /> Allergènes</Accordion.Header>
                                        <Accordion.Body>
                                            {cake.allergens && cake.allergens.map(allergen => allergen.name).join(', ')}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <CtaLayoutOne /> */}
        <SplashFooter parentClass="" />
        </main>
        </>
    )
}

export default Product;